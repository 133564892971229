<script setup lang="ts">
// PROPS
interface Props {
    placeholder?: string;
}
const props = withDefaults(defineProps<Props>(), {
    placeholder: 'ค้นหาด้วยไอดีหรือชื่อ',
});
const { placeholder } = toRefs(props);

// MODEL
const searchText = defineModel<string>({ required: true });

// EMIT
type Emit = {
    search: [];
};
defineEmits<Emit>();
</script>

<template>
    <form autocomplete="off">
        <div class="relative inline-flex w-full">
            <Input v-model="searchText" class="pl-10 h-full" type="text" :placeholder="placeholder" @input="$emit('search')" />
            <div class="absolute text-stone-600 dark:text-stone-400 inset-y-0 start-0 flex items-center pointer-events-none ps-4 peer-disabled:opacity-50 peer-disabled:pointer-events-none">
                <IconCSS name="material-symbols:search-rounded" size="1.25rem" />
            </div>
            <div class="absolute inset-y-0 end-0 flex items-center justify-center h-full p-1.5">
                <slot name="inner-end"></slot>
            </div>
        </div>
    </form>
</template>
